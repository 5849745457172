import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Form } from 'react-bootstrap';
import { TimeText, Weekday } from './DateHelpers'
import { ImageUpload } from './ImageUpload'

// Popup to edit (or create) a new program entry
export function EditProgramModal(props) {
    // Create an array of times that are selectable
    const selectableTimes = Array.from(Array(48), (x,index) => 
      {
        let hour = "0" + (Math.trunc(index/2));
        let min = (index%2) ? '30' : '00';
        return hour.substr(hour.length-2)+':'+min+':00';
      });

    const selectableDurations = selectableTimes.slice(1,17);
    
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.currentProgram.id ? ('Edit program') : ('Add program')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={props.onSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="input" placeholder="Program name" value={props.currentProgram.title || ''} onChange={props.onInputChange}/>
            </Form.Group>
            <Form.Group controlId="owner">
              <Form.Label>Owner</Form.Label>
              <Form.Control type="input" placeholder="Owner" value={props.currentProgram.owner || ''} onChange={props.onInputChange} />
            </Form.Group>
            <Form.Group controlId="location">
              <Form.Label>Location (e.g. sauna)</Form.Label>
              <Form.Control type="input" placeholder="Location" value={props.currentProgram.location || ''} onChange={props.onInputChange} />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" placeholder="Nice description about the program" value={props.currentProgram.description || ''} onChange={props.onInputChange} rows="5" />
            </Form.Group>
            <ImageUpload filename={props.currentProgram.filename} onFileChange={props.onFileChange} onInputChange={props.onInputChange}/>
            <Form.Group controlId="duration_planned">
              <Form.Label>Duration</Form.Label>
              <Form.Control as="select" value={props.currentProgram.duration_planned} onChange={props.onInputChange} >
              {selectableDurations.map(duration => (
                <option value={duration} key={duration}>{TimeText({time: duration, offset: []})}</option>
              ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="event_day_id">
              <Form.Label>Day</Form.Label>
              <Form.Control as="select" value={props.currentProgram.event_day_id || ''} onChange={props.onInputChange}>
                <option value='' key="0">Unscheduled</option>
              {props.eventDays.map(eventDay => (
                <option value={eventDay.id} key={eventDay.id}>{Weekday({date: eventDay.date})}</option>
              ))}
              {/* Need to call Weekday as JavaScript function instead of JSX as React does not support JSX components inside <option> */}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="time_planned">
              <Form.Label>Time</Form.Label>
              <Form.Control as="select" value={props.currentProgram.time_planned} onChange={props.onInputChange} disabled={props.currentProgram.event_day_id ? false : true}>
              {selectableTimes.map(time => (
                <option value={time} key={time}>{TimeText({time: time, offset: [props.dayBreakHour]})}</option>
              ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="is_public">
              <Form.Label>Published</Form.Label>
              <Form.Check type="checkbox" checked={props.currentProgram.is_public || ''} 
              onChange={(e) => {
                props.onInputChange({
                  target: {
                    id: e.target.id,
                    value: e.target.checked || false,
                  },
                });
              }}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  export default EditProgramModal
