import React from 'react';
import '../styles/FeedScreen.css';
import gql from 'graphql-tag';
import { useEvent } from './EventContext';
import { useQuery } from '@apollo/react-hooks';

export const QUERY_FEEDS= gql`
query QueryFeeds($event_id: Int!) {
    image_feed: feed(where: {_and: {event_id: {_eq: $event_id}, filename: {_is_null: false}}}, order_by: {created_at: desc}, limit: 4) {
        id
        text
        filename
      }
      text_feed: feed(where: {_and: {event_id: {_eq: $event_id}, filename: {_is_null: true}}}, order_by: {created_at: desc}, limit: 20) {
        id
        text
      }
}`;



export default function Feed(props) {
    const { eventID } = useEvent();
    const refLast = React.useRef(null); 
    const { data } = useQuery(QUERY_FEEDS, {variables: { event_id: eventID }, pollInterval: 500});

    function GetImagePath(filename)
    {
        return 'https://perse.vimpa.in/uploads/feed/'+eventID+'/'+filename+'.jpg'
    }

    function scrollToLatest()
    {
        if (refLast.current)
            refLast.current.scrollIntoView();
    }

    /* Scroll so that the latest item is visible */
    React.useEffect(() => {
        scrollToLatest();
    }, [data]);

    if (!data)
        return "";
    
    return (
        <div className="pageContainer">
            <div className="textContainer">
                {data.text_feed.length > 0 ? (
                    [...data.text_feed].reverse().map((feedItem, idx, arr) => {
                        if (idx === arr.length-1)
                            return (
                                <div className="feedItem" key={feedItem.id} ref={refLast}>
                                    <p>{feedItem.text}</p>
                                </div>);
                        else
                            return (
                                <div className="feedItem" key={feedItem.id}>
                                    <p>{feedItem.filename && (<img src={GetImagePath(feedItem.filename)} onLoad={scrollToLatest} />)}</p>
                                    <p>{feedItem.text}</p>
                                </div>);
                    })
                ) : (
                    <p>Nothing in feed</p>
                )}
            </div>
            <div className="imgContainer">
                {data.image_feed.length > 0 ? (
                    [...data.image_feed].reverse().map((feedItem) => (
                        <div className="feedImageContainer" key={feedItem.id}>
                            <p>{feedItem.filename && (<img src={GetImagePath(feedItem.filename)} onLoad={scrollToLatest} className="feedImage" />)}</p>
                            <p className="feedImageText">{feedItem.text} </p>
                        </div>
                    ))
                ) : (
                    <p></p>
                )}
            </div>
        </div>
    );
}
