import React from 'react';
import { Trash, PencilSquare } from 'react-bootstrap-icons';
import { TimeText } from './DateHelpers'

// Shows single day
export const DayTable = (props) => {
  return (
    <table className="table table-sm programEditTable">
      <thead>
        <tr>
          <th style={{width: "20%"}}>{props.noTime ? (<></>) : "Time"}</th>
          <th style={{width: "55%"}}>Title</th>
          <th style={{width: "25%"}}>Actions</th>
        </tr>
      </thead>
      <tbody>
      {props.programs.length > 0 ? (
        props.programs.map(program => (
        <tr key={program.id} className={program.is_public ? "published" : "unpublished"}>
          {props.noTime ? (<td></td>) : (
            <td><TimeText time={program.time_planned} offset={[props.dayBreakHour]}/>-
                <TimeText time={program.time_planned} offset={[props.dayBreakHour, program.duration_planned]}/></td>)}
          <td>{program.title}</td>
          <td>
            <button className="button muted-button" onClick={() => props.openEditProgram(program)}><PencilSquare /></button>
            <button className="button muted-button" onClick={() => props.deleteProgram(program.id)}><Trash /></button>
          </td>
        </tr>
      ))
      ) : (
        <tr>
          <td colSpan={3}>No programs</td>
        </tr>
      )}
      </tbody>
    </table>
  );
}

export default DayTable