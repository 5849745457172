import React from 'react';
import { useAuth0 } from "../react-auth0-spa";
import { useEvent } from './EventContext';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button } from 'react-bootstrap';

export const MUTATION_PUBLISH_SCHEDULE = gql`
mutation PublishSchedule($event_id: Int!, $public: Boolean!) {
  update_event(
    where: {
      id: {
        _eq: $event_id
      }
    }
    _set: {schedule_public:$public}
  )
  {
    affected_rows
  }
}
`;

export const Home = (props) => {
  const [schedulePublicState, setSchedulePublicState] = React.useState();
  const { isAuthenticated } = useAuth0();
  const { eventID, eventName, schedulePublic } = useEvent();
  const [ publishSchedule ] = useMutation(MUTATION_PUBLISH_SCHEDULE);

  React.useEffect(() => {
    setSchedulePublicState(schedulePublic);
  }, [schedulePublic]);

  if (!isAuthenticated)
    return (
      <div className="p-3">
        <p>Welcome to admin panel. Please log in from the top right.</p>
      </div>
    )
    else
     return (
      <div className="p-3">
        <h1>{eventName}</h1>
        <p>Welcome to admin panel.</p>
        {schedulePublicState ? (
        <span>
          <p>The schedule for this event is published. Both Schedule and Programs are visible in the app.</p>
          <Button className="m-3" variant="secondary" onClick={() => publishSchedule({variables: {event_id: eventID, public: false}}) && setSchedulePublicState(false)} >
            Unpublish schedule
          </Button>
        </span>
        ):(
        <span>
          <p>The schedule for this event is NOT published. The Schedule screen is not visible in the app.</p>
          <p>When individual programs are published in the Programs screen, they will appear in the programs listing, but the dates and times for each program are not public.</p>
          <Button className="m-3" variant="secondary" onClick={() => publishSchedule({variables: {event_id: eventID, public: true}}) && setSchedulePublicState(true)} >
            Publish schedule
          </Button>
        </span>
      )}
      </div>
     )
}

export default Home;
