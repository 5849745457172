// Formats a time field based on the GraphQL string
// Params:
//        time: the GraphQL string to show
//        offset: Array of GraphQL strings to add to the time string
export const TimeText = (props) => 
{
  // Helper function to pad the duration and time fields with leading zeros
  function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
  }

  let [hours, mins, secs] = props.time.split(':').map(Number);
  props.offset.map(offset =>
  {
    const [offset_hours, offset_mins, offset_secs] = offset.split(':').map(Number);
    hours += offset_hours;
    mins += offset_mins;
  });
  while (mins >= 60)
  {
    mins -= 60;
    hours += 1;
  }
  hours = hours%24;
  return pad(hours,2)+":"+pad(mins,2);
}

// Displays the weekday name
// Params:
//        date: GraphQL date string
export const Weekday = (props) => 
{
  // Names of weekdays
  const weekdayName = ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'];
  const date = new Date(props.date);
  return weekdayName[date.getDay()];
}