import React, { useState, createContext, useContext } from "react";
import { useAuth0 } from "../react-auth0-spa";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const FETCH_USER_ROLE = gql`
query UserRole($event_id: Int!, $user_id: String!) {
    event_role(where: {_and: {
              event_id: {_eq: $event_id},
              user_id: {_eq: $user_id}}}) {
      role
    }
  }
`;

const FETCH_EVENT_DETAILS = gql`
query EventDetails($event_id: Int!) {
    event(where: {id: {_eq: $event_id}}) {
      name
      day_break_hour
      schedule_public
    }
  }
`;

// This is holding the currently selected event ID
export const EventContext = React.createContext();
export const useEvent = () => React.useContext(EventContext);

export const EventProvider = ({
    children,
    eventID
  }) => {
    const [userRole, setUserRole] = useState(null);
    const [eventDetails, setEventDetails] = useState({name: null, day_break_hour: null, schedule_public: null});
    const { user } = useAuth0();
    const { errorRole } = useQuery(FETCH_USER_ROLE, {skip: (!user), variables: { event_id: eventID, user_id: user?.sub }, onCompleted: data => {data?.event_role.length && setUserRole(data.event_role[0].role)}, fetchPolicy: 'network-only' });
    const { errorDetails } = useQuery(FETCH_EVENT_DETAILS, {variables: { event_id: eventID }, onCompleted: data => {data?.event.length && setEventDetails(data.event[0])}, fetchPolicy: 'network-only' });
    if (errorRole) console.log(errorRole);
    if (errorDetails) console.log(errorDetails);
    
    return (
      <EventContext.Provider
        value={{
          eventID,
          userRole,
          eventName: eventDetails.name,
          dayBreakHour: eventDetails.day_break_hour,
          schedulePublic: eventDetails.schedule_public
        }}
      >
        {children}
      </EventContext.Provider>
    );
  };

export default EventContext;