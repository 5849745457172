import React, { Fragment } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { IsModerator } from "../UserPermissions"

const Profile = () => {
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      <h2>{user.nickname}</h2>
      <p>{user.email}</p>
      <p>Is moderator for this event: {IsModerator() ? "Yes" : "No" }</p>
    </Fragment>
  );
};

export default Profile;