import React, { Fragment } from "react";
import { Button, Form } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import { useAuth0 } from "../react-auth0-spa";


/* This can be used for development */
/*
export const QUERY_PUSH_TOKENS= gql`
query QueryPushTokens {
  push_token(limit: 100, where: {app: {_eq: "expo"}}) {
    token
  }
}`;
*/

/* For production */
export const QUERY_PUSH_TOKENS= gql`
query QueryPushTokens {
  push_token(limit: 100, where: {app: {_eq: "standalone"}}) {
    token
  }
}`;

export const MUTATION_ADD_NOTIFICATION = gql`
mutation AddNotification($object: notification_insert_input!) {
  insert_notification(objects: [$object])
  {
    affected_rows
  }
}
`;

const NotificationsScreen = () => {
  const { data, error, loading } = useQuery(QUERY_PUSH_TOKENS);
  const { user } = useAuth0();
  const [ addNotification ] = useMutation(MUTATION_ADD_NOTIFICATION);
  const initialNotificationState = {title: '', notification_text: '', full_text: ''};
  const [notificationState, setNotificationState] = React.useState(initialNotificationState);

  /* Send single push notification */
  const sendPush = (token, notification) => {
    const message = {
      to: [token.token],
      sound: 'default',
      title: notification.title,
      body: notification.notification_text,
      data: { title: notification.title, message: notification.full_text}
    };
    axios.post("https://perse.vimpa.in/api/push", JSON.stringify(message), { headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json', }
              })
              .then(res => { // then print response status
                if (res.status != 200 || !(res.data?.data?.length) || res.data.data[0].status != "ok")
                {
                  console.log("Notification failed");
                  console.log(res);
                }
              })
              .catch(error => {
                console.log("Notification failed");
                console.log(error);
              });
  }

  /* Handle the submit of the form */
  const onSubmit = (event) => {
    event.preventDefault(event);
    // Add notification first to the database
    addNotification({variables: {object: {...notificationState, user_id: user?.sub}}});

    // Then send all push notifications
    if (data?.push_token?.length)
    {
      data.push_token.map(token => sendPush(token, notificationState));
    }
    // Then clear form
    setNotificationState(initialNotificationState);
  }

  // Handle the changes in the form
  const handleInputChange = event => {
    const { id, value } = event.target;
    setNotificationState({ ...notificationState, [id]: (value === '' ? null : value) });
  }

  /* Render */
  return (
    <Fragment>
      <h2>Send notifications</h2>
      <p>There are a total of {data?.push_token?.length} devices to send push notifications.</p>
      {data?.push_token?.length > 0 && (
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control type="input" placeholder="Notification title" value={notificationState.title || ''} onChange={handleInputChange}/>
          </Form.Group>
          <Form.Group controlId="notification_text">
            <Form.Label>Notification text (shown in the phone notification)</Form.Label>
            <Form.Control type="input" placeholder="Text for the phone notification" value={notificationState.notification_text || ''} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="full_text">
            <Form.Label>Longer text (shown in the app main screen)</Form.Label>
            <Form.Control type="input" placeholder="Actual message" value={notificationState.full_text || ''} onChange={handleInputChange} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      )}
    </Fragment>
  );
};

export default NotificationsScreen;