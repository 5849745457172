import React from 'react';
/*import ImageUploadComponent from './ImageCrop/ImageUploadComponent' */
import './App.css';
import {Switch, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavigationBar } from './components/NavigationBar';
import Profile from "./components/Profile";
import Home from './components/HomeScreen';
import Programs from './components/ProgramsScreen';
import Feed from './components/FeedScreen'
import { ApolloProvider } from 'react-apollo';
import CreateApolloClient from './utils/apollo';
import { useAuth0 } from "./react-auth0-spa";
import { Router } from "react-router-dom";
import history from "./utils/history";
import PrivateRoute from "./components/PrivateRoute";
import { EventProvider } from './components/EventContext';
import NotificationsScreen from './components/NotificationsScreen'

const AppRoute = ({navbar, login_required, ...routeProps }) => {
  if (login_required)
    return (
      <>
        {navbar && (<NavigationBar />)}
        <PrivateRoute {...routeProps} />
      </>
    );
  else
    return (
      <>
        {navbar && (<NavigationBar />)}
        <Route {...routeProps} />
      </>
    );
};

function App() {
  const { loading } = useAuth0();

  // Use event 3 (P8) for production and event 1 for development environment
  let eventID = 3
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    eventID = 1;
  }
  const client = CreateApolloClient();

  if (!client || loading) {
    return null;
  } 
  else {
    return (
      <Router history={history}>
        <ApolloProvider client={client}>
          <EventProvider eventID={eventID}>
            <Switch>
              <AppRoute navbar exact path="/" component={Home} />
              <AppRoute login_required navbar path="/programs" 
                render={(props) => <Programs {...props} />}
              />
              <AppRoute login_required navbar path="/profile" component={Profile} />
              <AppRoute login_required path="/feed" component={Feed} />
              <AppRoute login_required navbar path="/notifications" component={NotificationsScreen} />
            </Switch>
          </EventProvider>
        </ApolloProvider>
      </Router>
    );
  }
}

export default App;
