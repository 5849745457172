import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useEvent } from './EventContext';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function GetImagePath(filename)
{
  const { eventID } = useEvent();
  return 'https://perse.vimpa.in/uploads/'+eventID+'/'+filename+'.jpg'
}


/**
     * Component to handle image uploads
     *
     * Props: 
     *      filename (current selected file, if any)
     *      onFileChange (function to handle new images coming in) 
     *      onInputChange (function to handle changes in the form data) 
*/
export function ImageUpload(props) {
    // The image size is defined here
    const imageSize = {width: 1024, height: 576}

    const [srcState, setSrcState] = React.useState(null);
    const [cropState, setCropState] = React.useState({ unit: 'px', aspect: imageSize.width / imageSize.height, width: imageSize.width });
    const [imageRefState, setImageRefState] = React.useState(null);

    // Handle the selected file
    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            setSrcState(reader.result)
        );
        reader.readAsDataURL(e.target.files[0]);
        }
    };

    /* Set image reference */
    function onImageLoaded(image) {
        setImageRefState(image);
    };

    /* Call the client side cropping & resizing function */
    function onCropComplete(crop) {
        if (imageRefState && cropState.width && cropState.height) {
            getCroppedImg(
                imageRefState,
                cropState,
                'newFile.jpeg'
            );
        }
    };

    /* Just set the crop state */
    function onCropChange(crop, percentCrop) {
        setCropState(crop);
    };

    /* This is needed to call the client side crop function after initially loading the image */
    React.useEffect(() => {
        onCropComplete(cropState);
    }, [imageRefState]);

    /* Client side cropping function */
    function getCroppedImg(image, crop, fileName) 
    {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = imageSize.width;
        canvas.height = imageSize.height;
        const ctx = canvas.getContext('2d');
        console.log("Cropping...")

        ctx.drawImage(
            image,                  // image
            crop.x * scaleX,        // sx
            crop.y * scaleY,        // sy
            crop.width * scaleX,    // sWidth
            crop.height * scaleY,   // sHeight
            0,                      // dx
            0,                      // dy
            imageSize.width,             // dWidth
            imageSize.height             // dHeight
        );

        return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
            }
            blob.name = fileName;
            props.onFileChange(blob);
        }, 'image/jpeg');
        });
    }

    return (
        <>
        {props.filename ? (
        <div>
        <img className="previewImg" src={GetImagePath(props.filename)} />
        <Button className="m-2" variant="danger"
            onClick={(e) => {
                props.onInputChange({
                    target: {
                    id: 'filename',
                    value: '',
                    },
                });
                props.onFileChange(null);
            }}
            >Remove image</Button>
        </div>) : (
        <div>
            <Form.Group controlId="image">
                <Form.Label>Image (jpg only)</Form.Label>
                <Form.File onChange={onSelectFile} accept="image/jpeg"/>
            </Form.Group>
            {srcState && (
                <ReactCrop
                src={srcState}
                crop={cropState}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
                />
            )}
        </div>
        )}
        </>);
}

export default ImageUpload;