import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";
import { IsModerator } from "../UserPermissions"

const Styles = styled.div`
  .navbar { 
      background-color: #222; 
      color: #FFFFFF;
    }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .nav-quote {
    position: absolute !important;
    left: 25%;
    color: #FFFFFF;
    right: 25%;
    font-style: italic;
  }
`;
export const NavigationBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <Styles>
      <Navbar expand="lg" variant="dark">
        <Navbar.Brand href="/">Per-admin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        {!isAuthenticated && (
          <>
          <Nav className="ml-auto">
            <Link to="" onClick={() => loginWithRedirect({})} className="nav-link">Log in</Link>
          </Nav>
          </>
      )}
      {isAuthenticated && (
        <>
          <Navbar.Text className="nav-quote d-none d-lg-block">With great power comes great responsibility</Navbar.Text>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item><Link to="/" className="nav-link">Home</Link></Nav.Item> 
              <Nav.Item><Link to="/profile" className="nav-link">Profile</Link></Nav.Item>
              {IsModerator() && (<ModeratorNavigationItems />)}
              <Nav.Item><Link to="" onClick={() => logout({ returnTo: window.location.origin })} className="nav-link">Log out</Link></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </>
      )}
      </Navbar>
    </Styles>
  )
}

const ModeratorNavigationItems = () => {
  return (
    <>
      <Nav.Item><Link to="/programs" className="nav-link">Programs</Link></Nav.Item>
      <Nav.Item><Link to="/feed" className="nav-link">Feed</Link></Nav.Item>
      <Nav.Item><Link to="/notifications" className="nav-link">Notifications</Link></Nav.Item>
    </>
  );
};