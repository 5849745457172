import gql from 'graphql-tag';


export const FRAGMENT_PROGRAM_FIELDS= gql`
fragment queryProgramFields on program {
  id
  title
  description
  location
  duration_planned
  owner
  time_planned
  event_day_id
  is_public
  filename
}
`;

export const FETCH_PROGRAMS_BY_DAYS = gql`
query ProgramsByDays($event_id: Int!) {
  event_day(where: {event_id: {_eq: $event_id}}, order_by: {date: asc}) {
    id
    date
    programs(order_by: {time_planned: asc}) {
      ...queryProgramFields
    }
  }
  unscheduled_program:program(where: {_and: {event_id: {_eq: $event_id}, event_day_id: {_is_null: true}}}, order_by: {title: asc}) {
    ...queryProgramFields
  }
}
${FRAGMENT_PROGRAM_FIELDS}
`;

export const FETCH_ALL_PROGRAMS = gql`
query AllPrograms($event_id: Int!) {
  program(where: {event_id: {_eq: $event_id}}, order_by: {title: asc}) {
    ...queryProgramFields
  }
  ${FRAGMENT_PROGRAM_FIELDS}
}
`;

export const ADD_PROGRAM = gql`
  mutation AddProgram($object: program_insert_input!) {
    insert_program(objects: [$object]) {
      returning {
        ...queryProgramFields
      }
    }
  }
  ${FRAGMENT_PROGRAM_FIELDS}
`;

export const UPDATE_PROGRAM = gql`
mutation UpdateProgram($id: Int!, $set: program_set_input!) {
  update_program(
    where: {
      id: {
        _eq: $id
      }
    }
    _set: $set
  )
  {
    returning {
      ...queryProgramFields
    }
  }
}
${FRAGMENT_PROGRAM_FIELDS}
`;

export const REMOVE_PROGRAM = gql`
mutation DeleteProgram($id: Int!) {
  update_program(where: {id: {_eq: $id}}, _set: {is_deleted: true}) {
    returning {
      ...queryProgramFields
    }
  }
}
${FRAGMENT_PROGRAM_FIELDS}
`;

export const MASS_PUBLISH_PROGRAM = gql`
mutation MassPublish($event_id: Int!, $public: Boolean!) {
  update_program(
    where: {
      event_id: {
        _eq: $event_id
      }
    }
    _set: {is_public:$public}
  )
  {
    returning {
      ...queryProgramFields
    }
  }
}
${FRAGMENT_PROGRAM_FIELDS}
`;