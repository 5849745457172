import React from 'react';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { useAuth0 } from "../react-auth0-spa";
import { setContext } from "apollo-link-context";

const GRAPHQL_ENDPOINT = `https://perse.vimpa.in/hasura/v1/graphql`;
const CreateApolloClient = () => {
  // used state to get accessToken through getTokenSilently(), the component re-renders when state changes, thus we have
  // our accessToken in apollo client instance.
  const [accessToken, setAccessToken] = React.useState("");

  const { getTokenSilently, loading } = useAuth0();
  if (loading) {
    return "Loading...";
  }

  // get access token
  const getAccessToken = async () => {
    // getTokenSilently() returns a promise
    try {
      const token = await getTokenSilently();
      setAccessToken(token);
      
    } catch (e) {
      console.log(e);
    }
  };
  getAccessToken();
  //console.log("Token: "+accessToken);
  const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT
  });

  const authLink = setContext((_, { headers }) => {
    const token = accessToken;
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      };
    } else {
      return {
        headers: {
          ...headers
        }
      };
    }
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
}
export default CreateApolloClient;
