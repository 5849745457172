import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button } from 'react-bootstrap';
import {DayTable} from './ProgramsDayTable'
import {EditProgramModal} from './EditProgramModal'
import {FETCH_PROGRAMS_BY_DAYS, ADD_PROGRAM, UPDATE_PROGRAM, REMOVE_PROGRAM, MASS_PUBLISH_PROGRAM} from './ProgramQuery'
import { Weekday } from './DateHelpers'
import { useEvent } from './EventContext';
import { useAuth0 } from "../react-auth0-spa";
import axios from 'axios';

// TODO get day break hour from the event wrapper

// This is the "main" function of this page
export default function Programs(props) {
  const { eventID, dayBreakHour } = useEvent();
  const { getTokenSilently } = useAuth0();
  // Fetch initial data from database
  const { data, error, loading } = useQuery(FETCH_PROGRAMS_BY_DAYS, {variables: { event_id: eventID }});
  if (error) console.log(error);

  // Initial state for the modal form components
  const initialFormState = {event_id: eventID ,id: null, title: '', owner: '', description: '', location: '', event_day_id: null, duration_planned: '01:00:00', time_planned: '12:00:00', is_public: false, filename: null};
    
  // Initial state for the modal form components
  const [formState, setFormState] = React.useState(initialFormState);
  // Visibility status for the modal form
  const [modalShow, setModalShow] = React.useState(false);
  // Handle state of the file upload in the modal
  const [fileState, setFileState] = React.useState(null);
  // JWT access token 
  const [accessToken, setAccessToken] = React.useState();


  // Handle the changes in the form
  const handleInputChange = event => {
    const { id, value } = event.target;
    setFormState({ ...formState, [id]: (value === '' ? null : value) });
  }
  // This gets fired when a program is either created or edited
  const onSubmit = (event) => {
    event.preventDefault(event);
    // Copy the array
    var graphQLSet = {...formState};

    // If file is selected, send the file to server, and then update the database
    if (fileState)
    {
      const fileFormData = new FormData();
      fileFormData.append('file', fileState);
      fileFormData.append('event_id', eventID);
      axios.post("https://perse.vimpa.in/api/upload", fileFormData, { headers: {authorization: `Bearer ${accessToken}`} // receive two parameter endpoint url ,form data 
        })
      .then(res => { // then print response status
        if (res.status == 200 && res.data.status == 'success')
        {
          console.log("We have uploaded "+res.data.filename);
          graphQLSet.filename = res.data.filename;
          editCreateProgram(graphQLSet);
        }
        else
        {
          console.log("Upload failed")
          window.alert("File upload was not successful. Updating the database anyway.");
          editCreateProgram(graphQLSet);
        }
      })
      .catch(error => {
        console.log("Upload failed");
        window.alert("File upload was not successful. Updating the database anyway.");
        editCreateProgram(graphQLSet);
      });
      
      // Clear file state afterwards to prevent us sending the same file again and again
      setFileState(null);
    }
    else
    {
      editCreateProgram(graphQLSet);
    }
    setModalShow(false);
    setFormState(initialFormState);
  };

  const handleFileChange = blob => {
    setFileState(blob);
  }

  // Open edit program modal
  const openEditProgram = program => {
    const { __typename, ...other } = program; //Filter out __typename from the program props
    setFormState(other);
    setModalShow(true);
  }

  // Open create program modal
  const openCreateProgram = () => {
    setFormState(initialFormState);
    setModalShow(true);
  }


  // This is to be called after a program is changed. Modify the database.
  function editCreateProgram(formState)
  {
    if (formState.id)
    {
      console.log("Editing program", formState);
      updateProgram({variables: {id: formState.id, set: formState}});
    }
    else
    {
      delete formState.id
      console.log("Creating program", formState)
      addProgram({variables: {object: formState}});
    }
  }
  
  // This is to be called when a program is deleted. Modify the database.
  function deleteProgram(id)
  {
    if(window.confirm('Delete the item?')){
      console.log("Deleting program", id);
      removeProgram({variables: {id: id}});
    }
  }

  const [addProgram] = useMutation(ADD_PROGRAM, {refetchQueries: [{query: FETCH_PROGRAMS_BY_DAYS, variables: { event_id: eventID }}]});
  const [updateProgram] = useMutation(UPDATE_PROGRAM, {refetchQueries: [{query: FETCH_PROGRAMS_BY_DAYS, variables: { event_id: eventID }}]});
  const [removeProgram] = useMutation(REMOVE_PROGRAM, {refetchQueries: [{query: FETCH_PROGRAMS_BY_DAYS, variables: { event_id: eventID }}]});
  const [massPublishPrograms] = useMutation(MASS_PUBLISH_PROGRAM, {refetchQueries: [{query: FETCH_PROGRAMS_BY_DAYS, variables: { event_id: eventID }}]});

  const getAccessToken = async () => {
    // getTokenSilently() returns a promise
    try {
      const token = await getTokenSilently();
      setAccessToken(token);
      
    } catch (e) {
      console.log(e);
    }
  };
  getAccessToken();


  if (!data) return null;
  // Draw the actual main screen
    return (
      <>
      <div className="container">
        <div className="row">
            <div className="col">
              <h2>Unscheduled</h2>
              <DayTable programs={data.unscheduled_program} noTime={true} dayBreakHour={dayBreakHour} deleteProgram={deleteProgram} openEditProgram={openEditProgram}/>
            </div>
          {data.event_day.map(eventDay => (
            <div className="col" key={eventDay.id}>
              <h2><Weekday date = {eventDay.date} /></h2>
              <DayTable programs={eventDay.programs} dayBreakHour={dayBreakHour} deleteProgram={deleteProgram} openEditProgram={openEditProgram}/>
            </div>
          ))}
        </div>
        <div className="row">
          <Button className="m-3" variant="primary" onClick={openCreateProgram}>
            Add new program
          </Button>
        </div>
        <div className="row">
          <table className="programEditTable">
            <tbody>
              <tr className="published"><td className="p-1">Published programs have green backgound</td></tr>
              <tr className="unpublished"><td className="p-1">Unpublished programs have red backgound</td></tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <Button className="m-3" variant="secondary" onClick={() => massPublishPrograms({variables: {event_id: eventID, public: true}})} >
            Publish all programs
          </Button>
          <Button className="m-3" variant="dark" onClick={() => massPublishPrograms({variables: {event_id: eventID, public: false}})} >
            Unpublish all programs
          </Button>
        </div>
      </div>
      <EditProgramModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={onSubmit}
        currentProgram={formState}
        onInputChange={handleInputChange}
        onFileChange={handleFileChange}
        eventDays={data.event_day}
        dayBreakHour={dayBreakHour}
      />
    </>
    )
}
/* {{data.unscheduled_program.length > 0 ? ( ):(<></>)} */